<template>
  <div class="camera">
    <div class="capture" id="image-area">
      <div class="camera--actions">
        <button class="close" @click.prevent="offCamera">
          <svg viewBox="0 0 32 32" width="40" height="40" fill="#000" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="6.25%">
            <path d="M2 30 L30 2 M30 30 L2 2" />
          </svg>
          <span class="sr-only">Close</span>
        </button>
        <h2 style="text-align:center;">Image Capture</h2>
        <button class="switch" @click.prevent="isFront = !isFront">
          <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48" width="40" height="40">
            <g fill="#000">
              <path d="M33.9,12.1H14.2L17.6,7c0.4-0.6,1-0.9,1.7-0.9h9.6c0.7,0,1.3,0.3,1.7,0.9L33.9,12.1z"/>
              <path d="M14,11H8V9.2C8,8.5,8.5,8,9.2,8h3.6C13.5,8,14,8.5,14,9.2V11z"/>
              <path d="M40,42H8c-2.2,0-4-1.8-4-4V14c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v24C44,40.2,42.2,42,40,42z"/>
            </g>
            <path fill="#fff" d="M34,25c0-5.5-4.5-10-10-10c-2.4,0-4.6,0.8-6.3,2.2l1.2,1.6c1.4-1.1,3.1-1.8,5.1-1.8c4.4,0,8,3.6,8,8h-3.5 l4.5,5.6l4.5-5.6H34z"/>
            <path fill="#fff" d="M29.1,31.2C27.7,32.3,25.9,33,24,33c-4.4,0-8-3.6-8-8h3.5L15,19.4L10.5,25H14c0,5.5,4.5,10,10,10 c2.4,0,4.6-0.8,6.3-2.2L29.1,31.2z"/>
          </svg>
          <span class="sr-only">Flip</span>
        </button>
      </div>
      <div v-if="cameraEnabled"><video ref="video" id="video" width="100%" height="auto" autoplay></video></div>
      <div v-show="!cameraEnabled"><canvas ref="canvas" id="canvas" width="100%" height="auto"></canvas></div>
      <div class="camera--buttons">
        <button class="capture" v-show="cameraEnabled" @click.prevent="capture()">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40" viewBox="0 20 65 60">
            <g transform="translate(5,70) scale(0.05,-0.05)">
              <path fill="#000" glyph-name="camera-2" unicode="" d="M536 475q66 0 113-47t47-114-47-113-113-47-114 47-47 113 47 114 114 47z m393 232q59 0 101-42t41-101v-500q0-59-41-101t-101-42h-786q-59 0-101 42t-42 101v500q0 59 42 101t101 42h125l28 76q11 27 39 47t58 20h286q29 0 57-20t39-47l29-76h125z m-393-643q103 0 176 74t74 176-74 177-176 73-177-73-73-177 73-176 177-74z"></path>
            </g>
          </svg>
          <span class="sr-only">Capture</span>
        </button>
        <button class="retake" v-show="!cameraEnabled" @click.prevent="retake()">
					<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="mdi-camera-retake" width="40" height="40" viewBox="0 0 24 24">
						<path d="M20,5H17L15,3H9L7,5H4A2,2 0 0,0 2,7V19A2,2 0 0,0 4,21H20A2,2 0 0,0 22,19V7A2,2 0 0,0 20,5M12,18C10.92,18 9.86,17.65 9,17L10.44,15.56C10.91,15.85 11.45,16 12,16A3,3 0 0,0 15,13A3,3 0 0,0 12,10C10.74,10 9.6,10.8 9.18,12H11L8,15L5,12H7.1C7.65,9.29 10.29,7.55 13,8.1C15.7,8.65 17.45,11.29 16.9,14C16.42,16.33 14.38,18 12,18Z" />
					</svg>
          <span class="sr-only">Retake</span>
        </button>
      </div>
    </div>
    <!-- <div v-show="!cameraEnabled">
      <canvas ref="canvas" id="canvas" width="100%" height="auto"></canvas>
      <button class="page_button" @click="retake()">Retake</button>
    </div> -->
    <!-- <div class="preview">
      <h2 style="text-align:center;">Image Preview</h2>
      <div class="col-md-6" style="text-align:center;">
        <img v-bind:src="previewURL" v-show="isPreview" alt="" width="200" height="200"/>
        <p style="text-align:center;">Name: {{ model.name }}</p>
        <p style="text-align:center;">Lat: {{ model.lat }}</p>
        <p style="text-align:center;">Long: {{ model.long }}</p>
      </div>
      <div v-show="isPreview">
        <button class="page_button" @click="retake()">Retake</button>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";
export default {
  name: "Camera",
  props: ["isCapturing"],
  data() {
    return {
      model: {
        name: "",
        picture: null,
        lat: "",
        long: "",
      },
      file: "",
      isPreview: false,
      previewURL: "",

      timeTaken: "",
      stream: {},
      coords: {},
      loading: "",
      uploadStatus: "",
      cameraEnabled: true,
      cameraDisabled: false,
      isFront: false,
      element: {
        width: "",
        height: "",
      },
    };
  },
  watch: {
    isFront() {
      this.mediaDevice();
      return this.isFront;
    },
  },
  mounted() {
    this.mediaDevice();
    // this.locate();
  },
  methods: {
    ...mapActions(["setMessage"]),
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    mediaDevice() {
      // this.video = this.$refs.video;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: this.isFront ? "user" : "environment",
              width: 1280,
              height: 720,
            },
          })
          .then((stream) => {
            // this.stream = stream;
            let video = this.$refs.video;
            this.element.height = video.offsetHeight;
            this.element.width = video.offsetWidth;
            video.srcObject = stream;
            video.play();
          })
          .catch((err) => {
            this.setMessage({ text: err.message, status: false });
            this.cameraDisabled = true;
            this.cameraEnabled = false;
          });
      }
    },
    snap() {
      this.mediaDevice();
    },
    retake() {
      this.isPreview = !this.isPreview;
      // this.cameraDisabled = !this.cameraDisabled;
      this.cameraEnabled = !this.cameraEnabled;
      this.mediaDevice();
    },
    async capture() {
      // Takes a snap shot and disables the camera
      // this.locate();
      let height = this.element.width / (16 / 9);
      let video = this.$refs.video;
      let canvas = this.$refs.canvas;
      canvas.width = this.element.width;
      canvas.height = height;
      var context = canvas.getContext("2d");
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = "high";
      context.drawImage(video, 0, 0, this.element.width, height);
      this.isPreview = true;
      this.previewURL = canvas.toDataURL("image/png");
      this.file = this.dataURItoBlob(canvas.toDataURL());
      this.model.picture = this.dataURItoBlob(canvas.toDataURL());
      /**
       * As per https://jsfiddle.net/sasivarunan/2xLbv7n9/
       */
      video.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      this.cameraEnabled = false;
    },
    async offCamera() {
      let video = this.$refs.video;
      if (video) video.srcObject.getTracks().forEach((track) => track.stop());
      this.cameraEnabled = false;
      this.$emit("offCamera", this.file, this.previewURL);
      // this.isCapturing = false;
    },
  },
};
</script>

<style scoped>
/* camera */
.camera {
  background-color: rgba(243, 244, 246, 1);
  position: absolute;
  height: auto;
  width: 95%;
  left: 0%;
  right: 0%;
  top: 15%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -15px;
  z-index: 10000;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.camera--actions {
  display: flex;
  justify-content: space-between;
}
.camera--buttons {
  display: flex;
}
.capture,
.retake {
  flex: auto;
}
.capture svg,
.retake svg {
  display: inline;
}
.sr-only {
  display: none;
}
/* Wider screen */
@media screen and (min-width: 981px) {
  .camera {
    background-color: rgba(243, 244, 246, 1);
    position: absolute;
    height: auto;
    width: 40%;
    left: 0%;
    right: 0%;
    top: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -25px;
    z-index: 10000;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }
}
</style>