<template>
	<div class="form">
		<Header></Header>
    <Camera v-if="isCapturing" :isCapturing="isCapturing" @offCamera="offCamera"></Camera>
		<div v-if="Object.keys(form).length !== 0" class="form--wrapper">
			<div class="form--content">
				<div class="form--header">
					<h2 class="page--title">{{form.title}}</h2>
				</div>
				<hr style="border: 1px solid #374151; margin: 0px 0px 20px;">
        <div id="myProgress">
          <div id="myBar" styyle="" :style="{ width: checkProgress + '%' }">
            <span id="myCounter">{{`${Math.ceil(checkProgress)}%`}}</span>
          </div>
        </div>
				<div v-if="!!validations.length" class="validations">
					<ul style="text-align: left; list-style-type: disc;">
						<li style="text-transform: capitalize;" v-for="(validation, index) in validations" :key="index">{{validation}}</li>
					</ul>
				</div>
				<form class="forms" @submit.prevent="onUpdateRecord">
					<div class="form--item" v-for="(item, index) in items.items" :key="index">
						<label class="form--label" :for="item.name" :class="{required: item.required}">{{item.label}}:</label>
						<select v-if="item.tag === 'select'" v-model="item.value" :name="item.name" :id="item.id" @blur="onBlur($event)" :required="item.required" style="width: 100%;">
							<option>--</option>
							<option v-for="(option, counter) in item.options" :key="counter">{{option.name}}</option>
						</select>
            <div v-else-if="item.tag === 'image'" style="display: flex;">
              <input class="form--input" :type="item.type" :name="item.name" :id="item.id" v-model="item.value" @click.prevent="onCamera(item)" :aria-required="item.ariaRequired" :aria-invalid="item.ariaInvalid">
              <img src="" alt="" height="52" style="margin-top: 5px;">
            </div>
            <input class="form--input" v-else-if="item.tag === 'file'" :type="item.type" :name="item.name" :id="item.id" v-model="item.value" @change.prevent="handleUpload" :aria-required="item.ariaRequired" :aria-invalid="item.ariaInvalid">
						<input class="form--input" v-else :type="item.type" :name="item.name" :id="item.name" v-model="item.value" @blur="onBlur($event)" :placeholder="item.placeholder" :aria-required="item.ariaRequired" :aria-invalid="item.ariaInvalid" :required="item.required">
					</div>
					<div class="form--item">
						<span>By clicking Save Button, you agree to our <router-link class="anchors" :to="{ name: 'Terms' }"> Terms of Use </router-link> and our <router-link class="anchors" :to="{ name: 'Privacy' }"> Privacy Policy.</router-link></span>
					</div>
					<div class="form--item">
						<button type="submit" class="form--button" :class="{isValid: true}">Submit</button>
					</div>
				</form>
			</div>
		</div>
		<Main></Main>
		<Footer></Footer> 
	</div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import Main from "@/components/partials/Main.vue";
import Camera from "@/components/partials/Camera.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Form",
  components: {
    Header,
    Footer,
    Main,
    Camera,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCapturing: false,
      file: "",
      validations: [],
      items: {},
      input: {},
    };
  },
  computed: {
    ...mapGetters(["profile"]),
    item() {
      if (Object.entries(this.items).length === 0) return;
      var items = {};
      items.id = this.items.id;
      items.title = this.items.title;
      items.user = this.profile.email;
      items.created_at = Date.now();
      this.items.items.filter((item) => {
        if (item.type === "file") items[item.name] = item.url;
        else items[item.name] = item.value;
      });
      return items;
    },
    checkProgress() {
      if (!this.item) return;
      const len = Object.keys(this.item).length - 4;
      const val =
        Object.values(this.item)
          .map((value) => value !== null && value !== "")
          .filter(Boolean).length - 4;
      // if (val === 0) return 0
      return (val / len) * 100;
    },
  },
  mounted() {
    this.items = JSON.parse(JSON.stringify(this.form));
  },
  methods: {
    ...mapActions([
      "addRecordImage",
      "updateRecord",
      "setOverlaying",
      "setLoading",
    ]),
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(229,231,235, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    async handleUpload(e) {
      // TODO: Upload file
      let file = e.target.files[0] || e.dataTransfer.files[0];
      if (!file) return;
      this.setLoading(true);
      var name = `${this.items.id}_${e.target.id} _${Date.now()}`;
      let formData = new FormData();
      formData.append("file", file, name);
      const data = await this.addRecordImage(formData);
      this.items.items.filter((item) => {
        if (item.id === +e.target.id) {
          item["url"] = data;
        }
      });
      this.setLoading(false);
    },
    onCamera(input) {
      this.isCapturing = true;
      this.setOverlaying(true);
      this.input = input;
    },
    async offCamera(file, url) {
      this.isCapturing = false;
      this.setOverlaying(false);
      if (!file) return;
      this.setLoading(true);
      var name = `${this.items.id}_${this.input.id} _${Date.now()}`;
      let formData = new FormData();
      formData.append("file", file, name);
      const data = await this.addRecordImage(formData);
      this.items.items.filter((item) => {
        if (item.id === this.input.id) {
          item["url"] = data;
        }
      });
      document.getElementById(`${this.input.id}`).nextElementSibling.src = url;
      this.setLoading(false);
    },
    validate() {
      // TODO: Validate empty fields
      this.validations = [];
      for (let item of this.items.items) {
        if (item.type !== "file" && item.required && item.value === "")
          this.validations.push(`${item.label} is required!`);
      }
      setTimeout(() => (this.validations = []), 5000);
      if (this.validations.length) return false;
      return true;
    },
    async onUpdateRecord() {
      if (!this.validate()) return;
      this.setLoading(true);
      const omit = (obj, arr) =>
        Object.keys(obj)
          .filter((k) => !arr.includes(k))
          .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});

      let item = {};
      item.id = this.item.id;
      item.title = this.item.title;
      item.records = omit(this.item, ["id", "title"]);
      await this.updateRecord(item);
      await this.items.items.filter((item) => {
        if (item.tag === "image") {
          document.getElementById(`${item.id}`).nextElementSibling.src = "";
        }
      });
      this.items = JSON.parse(JSON.stringify(this.form));
      this.setLoading(false);
    },
  },
};
</script>

<style scoped>
/* form */
.form {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
}
.form--wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0rem;
}
.page--title {
  text-align: center;
  flex: auto;
  margin: 10px 0 5px 0;
}
.form--content {
  padding: 10px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
}
.form--header {
  display: flex;
}
.form--header button {
  background-color: rgba(209, 213, 219, 1);
  padding: 0.75rem 1rem;
  border-radius: 0;
}
#myProgress {
  /* width: 100%; */
  height: 30px;
  position: relative;
  background-color: #ddd;
  margin-left: 5px;
  margin-right: 5px;
}
#myBar {
  background-color: #4caf50;
  width: 0px;
  height: 30px;
  position: absolute;
  transition: width 2s ease-in-out;
}
#myCounter {
  vertical-align: -webkit-baseline-middle;
}
.validations {
  display: flex;
  justify-content: center;
  color: #ff0000;
  padding: 0.5rem;
  border-radius: 5px;
}
.forms {
  padding: 5px;
}
.required:after {
  color: #ff0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.form--item {
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0px 0px 20px;
  padding: 0px;
}
.form--label {
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  height: 16px;
  text-align: left;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.form--input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 4px;
  height: 52px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  transition: border-color 150ms ease-in-out 0s;
}
.form--button {
  position: relative;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-box-align: stretch;
  height: 45px;
  width: 100%;
  font-size: 18px;
  margin-top: 48px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
  cursor: not-allowed;
  z-index: 0;
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.form--button.isValid {
  cursor: pointer;
  background-color: rgba(209, 213, 219, 1);
}
.form--button.isValid:hover {
  opacity: 0.5;
}
.sr-only {
  display: none;
}
/* Wider screen */
@media screen and (min-width: 600px) {
  .form--wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 2rem 0rem;
  }
  .form--content {
    width: 60%;
  }
}
/* Wider screen */
@media screen and (min-width: 981px) {
  .form--content {
    width: 40%;
  }
}
</style>